import { Controller } from 'stimulus';

export default class extends Controller {
  initialize() {
    this.handleDownload()
  }

  handleDownload() {
    if (this.element.dataset.redirectUrl) {
      if (this.element.dataset.notice) {
        $(".alert.success.js-triggered .flash_notice")[0].innerHTML = this.element.dataset.notice
        $(".alert.success.js-triggered").show()
      }

      const newWindow = window.open(this.element.dataset.redirectUrl, '_blank');
      window.location.href = this.element.dataset.returnUrl;;

      setTimeout(() => {
        if (!newWindow.closed) {
          newWindow.close();
        }
      }, 2000);
    }
  }
}
